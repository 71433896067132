@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Knewave&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  overflow: hidden;
  text-align: start;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: rgb(255, 255, 255);
  /* background: linear-gradient(to right, #FDFBFB, #EBEDEE 70%); */
}

*{
  text-align: start;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.oswald{
  font-family: 'Oswald', sans-serif;
  background: linear-gradient(to right, #2FB8FF 0%, #8FE4DD 100%);
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.orbitron{
  font-family: 'Orbitron', sans-serif;
  background: linear-gradient(to right, #D10955 0%, #FEBE54 100%);
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.knewave{
  font-family: 'Knewave', cursive;
  font-weight: 100;
  background: linear-gradient(to right, #F15E85 0%, #6572D8 100%);
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.monoton{
  font-family: 'Monoton', cursive;
  font-weight: 200;
  background: linear-gradient(to right, #A04FAB 0%, #EEB96D 100%);
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.press{
  font-family: 'Press Start 2P', cursive;
  background: linear-gradient(to right, #A04FAB 0%, #EEB96D 100%);
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.mainText{
  font-family: 'Inter', sans-serif;
  padding: 20px;
  position: absolute;
  top: 50vh;
  transform: translateY(-60%);
  margin-left: 20px;
  
}

.mainText h1{
  font-size: 80px;
  font-weight: 900;
  text-align: start;
  margin: 0%;
}

.mainText h2{
  font-size: 50px;
  font-weight: 900;
  text-align: start;
  margin: 0;

}

.light{
  font-weight: 200;
}

.versionbox{
  background-image: url(./img/svgbox-01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px;
  margin: 20px;
  position: absolute;
  right: 0;
}

.versionbox h4{
  font-weight: 900;
  font-size: 20px;
}
.versionbox h5{
  font-weight: 100;
  font-size: 20px;
}


.versionbox h3{
  font-family: 'Rubik', sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 30px;
}
/* .linkbox{
  position: absolute;
  bottom: 0px;
  left: 50vw;
  transform: translateX(-50%);
} */

.icon {
  margin: 20px 10px;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  transition-delay: 2;
  /* transition-timing-function: ease-out; */
}

.icon-linkedin {
  background-image: url(./img/linked-in-01.svg);
}
.icon-linkedin:hover,
.icon-linkedin:focus {
  background-image: url(./img/linked-in-active-01.svg);
}
.icon-github {
  background-image: url(./img/github-01.svg);
}
.icon-github:hover,
.icon-github:focus {
  background-image: url(./img/github-active-01.svg);
}
.icon-instagram {
  background-image: url(./img/instagram-01.svg);
}
.icon-instagram:hover,
.icon-instagram:focus {
  background-image: url(./img/instagram-active-01.svg);
}



@media (max-width: 992px) { 
  .mainText h1{
    font-size: 60px;
  }
  .mainText h2{
    font-size: 40px;

  }
  .versionbox h4{
    font-weight: 900;
    font-size: 15px;
  }
  .versionbox h5{
    font-weight: 100;
    font-size: 15px;
  }
  
  .versionbox h3{
    font-weight: 900;
    font-size: 25px;
  }

 

}

@media (max-width: 575.98px) {  
  .mainText h1{
    font-size: 40px;

  }
  .mainText h2{
    font-size: 25px;
  }
  .versionbox h4{
    font-weight: 900;
    font-size: 10px;
  }
  .versionbox h5{
    font-weight: 100;
    font-size: 10px;
  }
  
  .versionbox h3{
    font-weight: 900;
    font-size: 20px;
  }
  .headertext h1{
    font-size: 30px;
  }
}

@media (max-width: 400px) {  
  .mainText{
    font-family: 'Inter', sans-serif;
    padding: 10px;
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    margin-left: 10px;
  }
  .mainText h1{
    font-size: 30px;
  
  }
  .mainText h2{
    font-size: 20px;
  }
  .versionbox h4{
    font-weight: 900;
    font-size: 10px;
  }
  .versionbox h5{
    font-weight: 100;
    font-size: 10px;
  }
  
  .versionbox h3{
    font-weight: 900;
    font-size: 15px;
  }
  .icon {
    width: 20px;
    height: 20px;
    /* transition-timing-function: ease-out; */
  }

}

.iconbutton {
  float: left;
  width: 200px;
  height: 40px;
  cursor: pointer;
  
  background: #fff;
  overflow: hidden;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  
}

.iconbutton h5 {
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 10px;
  color: black;
  
 
  
}

.iconbutton .icons {
  width: 40px;
  height: 40px;
  margin: 0px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.iconbutton:hover {
  width: 200px;
}

.iconbutton:nth-child(1):hover .icons {
  background: #e1306c;
}

.iconbutton:nth-child(2):hover .icon {
  background: #4267b2;
}

.iconbutton:nth-child(3):hover .icon {
  background: #1da1f2;
}

.iconbutton:nth-child(4):hover .icon {
  background: #0e76a8;
}

.iconbutton:nth-child(5):hover .icon {
  background: #ff0000;
}

.iconbutton:nth-child(6):hover .icon {
  background: #333;
}


.iconbutton:nth-child(1) span {
  color: #e1306c;
}

.iconbutton:nth-child(2) span {
  color: #4267b2;
}

.iconbutton:nth-child(3) span {
  color: #1da1f2;
}

.iconbutton:nth-child(4) span {
  color: #0e76a8;
}

.iconbutton:nth-child(5) span {
  color: #ff0000;
}

.iconbutton:nth-child(6) span {
  color: #333;
}


.social-menu ul{
  /* position: absolute;
  top: 50%;
  left: 50%; */
  padding: 0;
  margin: 5px;
  /* transform: translate(-50%, -50%); */
  display: flex;
}

.social-menu ul li{
  list-style: none;
  margin: 0 10px;
}

.social-menu ul li .fab{
  font-size: 20px;
  line-height: 30px;
  transition: .3s;
  color: #000;
}

.social-menu ul li .fab:hover{
  color: #fff;
}

.social-menu ul li a{
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: .6s;
  box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
  transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
  background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover{
  background-color: #E4405F;
}
.social-menu ul li:nth-child(3) a:hover{
  background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover{
  background-color: #000;
}

